import './TermsDisclaimer.scss';

import { useConfig } from '@travelwin/core';
import { Trans } from 'react-i18next';

import { useExternalHomepageConfig } from '../../hooks/useExternalHomepageConfig';
import { Anchor } from '../Anchor';

const className = 'c-TermsDisclaimer';

export function TermsDisclaimer() {
  const { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } = useConfig();
  const { getLocalisedUrl } = useExternalHomepageConfig();

  return (
    <p className={className}>
      <Trans
        i18nKey="login.conditions_disclaimer"
        defaults="By continuing, you agree to the <terms-link>Terms and Conditions</terms-link> and the <privacy-link>Privacy and Cookie Policy</privacy-link>."
        components={{
          'terms-link': (
            <Anchor
              href={getLocalisedUrl(TERMS_AND_CONDITIONS_URL)}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
          'privacy-link': (
            <Anchor
              href={getLocalisedUrl(PRIVACY_POLICY_URL)}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
        }}
      />
    </p>
  );
}
